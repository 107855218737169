<template>
  <div class="border rounded p-2">
    <h4 class="mb-1">
      Featured Image
    </h4>
    <b-media
      no-body
      vertical-align="center"
      class="flex-column flex-md-row"
    >
      <b-media-aside>
        <b-img
          ref="refPreviewEl"
          :src="getImage"
          height="110"
          width="170"
          class="rounded mr-2 mb-1 mb-md-0"
        />
      </b-media-aside>
      <b-media-body>
        <small class="d-block mb-2 text-muted">Required image resolution 800x400, image size 10mb.</small>
        <div class="d-inline-block">
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            placeholder="Choose file"
            @input="inputImageRenderer"
          />
        </div>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    imageData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
    }
  },
  computed: {
    getImage() {
      return this.imageData.imageUploaded ? this.imageData.imageUploaded : this.imageData.image
    },
  },
  setup(props) {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.imageData.imageUploaded = base64
    })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>
