import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBlogSetting() {
  // Use toast
  const toast = useToast()

  const statusOptions = ['Published', 'Archived', 'Draft']

  const visibilityOptions = ['Public', 'Private']

  const createPost = queryParams => axios
    .post('/auth/posts', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Post was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating post',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updatePost = queryParams => axios
    .put(`/auth/posts/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Post was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating post',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchPost = ID => axios
    .get(`/auth/posts/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching post item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const getAbout = ID => axios
    .get(`/auth/about/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching About Info',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const setAbout = queryParams => axios
    .put(`/auth/about/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'About Info was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating About Info',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    createPost,
    updatePost,
    fetchPost,
    getAbout,
    setAbout,
    statusOptions,
    visibilityOptions,
  }
}
