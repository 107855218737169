<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      v-if="postData === undefined"
      variant="danger"
    >
      <h4 class="alert-heading">
        Error fetching post data
      </h4>
      <div class="alert-body">
        No post found with this post id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-cms'}"
        >
          CMS List
        </b-link>
        for other posts.
      </div>
    </b-alert>

    <b-card
      v-else
      class="blog-edit-wrapper"
    >
      <validation-observer
        ref="blogRules"
        tag="form"
      >
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center mb-2">
                <h3 class="mb-0 font-weight-normal">
                  Blog Post
                </h3>
              </div>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="postData.title"
                    @input="generateSlug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Category"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <b-form-tags
                    v-model="postData.categories"
                    input-id="tags-basic"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Program"
                label-for="blog-edit-program"
                class="mb-2"
              >
                <v-select
                  id="blog-edit-program"
                  v-model="postData.program"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="programsList"
                  :placeholder="'Select Program'"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Slug"
                label-for="blog-edit-slug"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Slug"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-slug"
                    v-model="postData.slug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Visibility"
                label-for="blog-edit-visibility"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Visibility"
                  rules="required"
                >
                  <v-select
                    id="blog-edit-visibility"
                    v-model="postData.visibility"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="visibilityOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Time Published"
                label-for="blog-edit-date"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Time Published"
                  rules="required"
                >
                  <flat-pickr
                    v-model="postData.posted_at"
                    class="form-control"
                    placeholder="Time Picker"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Status"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    id="blog-edit-category"
                    v-model="postData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Content"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required"
                >
                  <quill-editor
                    id="blog-content"
                    v-model="postData.content"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <BlogImage
                :image-data="postData"
              />
              <small
                v-if="imageError && !postData.imageUploaded"
                class="text-danger"
              >The Image is required</small>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="handleForm"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'admin-cms'}"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BButton,
  BFormTags,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { onUnmounted, ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import storeModule from '@/views/admin/blog/blogStoreModule'
import useBlogSetting from '@/views/admin/blog/useBlogSetting'
import router from '@/router'
import BlogImage from '@/views/admin/blog/blog-component/BlogImage.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BForm,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    vSelect,
    quillEditor,
    flatPickr,
    BlogImage,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      postFile: null,
      programsList: [],
      required,
      imageError: false,
    }
  },
  setup() {
    const postData = ref(null)
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      postData.image = base64
    })

    const INVOICE_APP_STORE_MODULE_NAME = 'app-cms'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPost,
      updatePost,
      statusOptions,
      visibilityOptions,
    } = useBlogSetting()

    return {
      postData,
      refInputEl,
      refPreviewEl,
      inputImageRenderer,

      fetchPost,
      updatePost,

      statusOptions,
      visibilityOptions,
    }
  },
  async created() {
    const postID = router.currentRoute.params.id
    if (postID) this.postData = await this.fetchPost(postID)

    this.programsList = await this.$store.dispatch('app-cms/fetchProgramList')
      .then(response => response.data.data.reduce((arr, item) => {
        arr.push({ id: item.id, label: item.name })
        return arr
      }, []))
  },
  methods: {
    updateArticle() {
      this.updatePost(this.postData)
      this.$router.push({ name: 'admin-cms' })
    },
    generateSlug(text) {
      if (!(text instanceof Event)) {
        this.postData.slug = text
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
      }
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.blogRules.validate().then(success => {
          if (!this.postData.imageUploaded) {
            this.imageError = true
            reject()
          }
          if (success) {
            resolve(true)
            this.updateArticle()
          } else {
            window.scrollTo(0, 0)
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
  @import '@core/scss/vue/pages/page-blog.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
